<template>
  <div class="color-picker" ref="colorpicker">
    <div class="color-picker__input">
      <vs-input
        :label="label"
        v-model="colorValue"
        @focus="showPicker()"
        @input="updateFromInput"
      />
      <span class="color-picker-container">
        <span
          class="current-color"
          :style="'background-color: ' + colorValue"
          @click="togglePicker()"
        ></span>
        <chrome-picker
          :value="colors"
          @input="updateFromPicker"
          v-if="displayPicker"
        />
      </span>
    </div>
  </div>
</template>

<script>
import { Chrome } from 'vue-color'

export default {
  name: 'ColorPicker',
  components: {
    'chrome-picker': Chrome
  },
  props: {
    color: String,
    label: String
  },
  data() {
    return {
      colors: {
        hex: '#000000'
      },
      displayPicker: false
    }
  },
  computed: {
    colorValue: {
      get() {
        return this.color
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  mounted() {
    this.setColor(this.color || '#000000')
  },
  methods: {
    setColor(color) {
      this.updateColors(color)
      this.colorValue = color
    },
    updateColors(color) {
      if (color.slice(0, 1) == '#') {
        this.colors = {
          hex: color
        }
      } else if (color.slice(0, 4) == 'rgba') {
        let rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(','),
          hex =
            '#' +
            (
              (1 << 24) +
              (parseInt(rgba[0]) << 16) +
              (parseInt(rgba[1]) << 8) +
              parseInt(rgba[2])
            )
              .toString(16)
              .slice(1)
        this.colors = {
          hex: hex,
          a: rgba[3]
        }
      }
    },
    showPicker() {
      document.addEventListener('click', this.documentClick)
      this.displayPicker = true
    },
    hidePicker() {
      document.removeEventListener('click', this.documentClick)
      this.displayPicker = false
    },
    togglePicker() {
      this.displayPicker ? this.hidePicker() : this.showPicker()
    },
    updateFromInput() {
      this.updateColors(this.colorValue)
    },
    updateFromPicker(color) {
      this.colors = color
      if (color.rgba.a == 1) {
        this.colorValue = color.hex
      } else {
        this.colorValue = `rgba('${color.rgba.r}', '${color.rgba.g}', '${color.rgba.b}', '${color.rgba.a}')`
      }
    },
    documentClick(e) {
      let el = this.$refs.colorpicker,
        target = e.target
      if (el !== target && !el.contains(target)) {
        this.hidePicker()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.color-picker {
  &__input {
    display: flex;
    align-items: flex-end;
    flex-grow: 1;
    .vs-con-input-label {
      width: 100%;
      .vs-con-input {
        border-radius: 0 !important;
      }
    }
    .color-picker-container {
      position: relative;
      width: 38.59px;
      height: 38.59px;
    }
  }
  .vc-chrome {
    position: absolute;
    top: 45px;
    z-index: 999;
  }
  .current-color {
    position: absolute;
    right: 1px;
    display: inline-block;
    width: 38.59px;
    height: 38.59px;
    background-color: #000;
    cursor: pointer;
    border-radius: 0 5px 5px 0;
  }
  .footer {
    margin-top: 20px;
    text-align: center;
  }
}
.vs-con-input .vs-inputx {
  border-radius: 0;
}
</style>
